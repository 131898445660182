import Defaults from '../constants/Defaults';
class ResetCountFormatterService {
  static format(seconds) {
    const secondsPerMinute = 60;
    const secondsPerHour = secondsPerMinute * 60;
    const secondsPerDay = secondsPerHour * 24;
    if (seconds === Defaults.NO_VALUE) {
      return 'Fully charged';
    } else if (seconds < secondsPerMinute) {
      return '< 1 minute to reset';
    } else if (seconds < secondsPerHour) {
      const value = Math.round(seconds / secondsPerMinute);
      const text = value > 1 ? 'minutes' : 'minute';
      return `${value} ${text} to reset`;
    } else if (seconds < secondsPerDay) {
      const hours = Math.floor(seconds / secondsPerHour);
      const minutesRound = Math.round(seconds % secondsPerHour / secondsPerMinute);
      const minutes = minutesRound === 60 ? 59 : minutesRound; // Handle round to 60
      return `${hours}h ${minutes}m to reset`;
    } else if (seconds >= secondsPerDay) {
      const days = Math.floor(seconds / secondsPerDay);
      const hours = Math.floor(seconds % secondsPerDay / secondsPerHour);
      const minutes = Math.floor(seconds % secondsPerDay % secondsPerHour / secondsPerMinute);
      return `${days}d ${hours}h ${minutes}m to reset`;
    } else {
      return 'unknown time to reset';
    }
  }
}
export default ResetCountFormatterService;