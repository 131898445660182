import { UnleashClient } from 'unleash-proxy-client';
import { SourceService } from '../services/SourceService';
import Urls from '../constants/Urls';
const getSessionId = SourceService.createRequest({
  method: 'GET',
  parse: data => data && data.sessionId || null
});
export const unleashClient = new UnleashClient({
  url: process.env.UNLEASH_PROXY_URL || '',
  clientKey: process.env.UNLEASH_CLIENT_KEY || '',
  appName: process.env.UNLEASH_APP_NAME || ''
});
export const init = ({
  env,
  dataLayer,
  userId
}) => {
  const isProduction = env === 'production';
  getSessionId({
    requestURL: `${Urls.MANGOOLS_APP_HOST}/users/unleash`,
    credentials: 'include'
  }).then(({
    payload: sessionId
  }) => {
    unleashClient.updateContext({
      sessionId,
      userId
    });
    unleashClient.start();
    if (!isProduction) {
      console.log(`[UNLEASH]: session: ${sessionId}, user: ${userId}`);
    }
  }).catch(() => {
    console.error('unleash session not set');
  });
};
export const gtmLogUnleash = () => {
  const toggleToTrack = 'early-bird-ab-test';
  if (window.dataLayer && unleashClient.isEnabled(toggleToTrack)) {
    window.dataLayer.push({
      event: 'unleash',
      ABTestName: 'earlybird',
      ABTestVariant: unleashClient.getVariant(toggleToTrack).name
    });
  }
};