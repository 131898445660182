export const ANYWHERE = {
  code: 'us',
  id: 0,
  label: 'Anywhere',
  type: 'Default'
};
export const BASIC_LOCATIONS = [{
  code: 'us',
  id: 2840,
  label: 'United States',
  type: 'Country'
}, {
  code: 'de',
  id: 2276,
  label: 'Germany',
  type: 'Country'
}, {
  code: 'gb',
  id: 2826,
  label: 'United Kingdom',
  type: 'Country'
}, {
  code: 'es',
  id: 2724,
  label: 'Spain',
  type: 'Country'
}, {
  code: 'fr',
  id: 2250,
  label: 'France',
  type: 'Country'
}];
export const SEMRUSH_LOCATIONS = [ANYWHERE, {
  code: 'au',
  id: 2036,
  label: 'Australia',
  type: 'Country'
}, {
  code: 'at',
  id: 2040,
  label: 'Austria',
  type: 'Country'
}, {
  code: 'be',
  id: 2056,
  label: 'Belgium',
  type: 'Country'
}, {
  code: 'br',
  id: 2076,
  label: 'Brazil',
  type: 'Country'
}, {
  code: 'ca',
  id: 2124,
  label: 'Canada',
  type: 'Country'
}, {
  code: 'cz',
  id: 2203,
  label: 'Czechia',
  type: 'Country'
}, {
  code: 'dk',
  id: 2208,
  label: 'Denmark',
  type: 'Country'
}, {
  code: 'fi',
  id: 2246,
  label: 'Finland',
  type: 'Country'
}, {
  code: 'fr',
  id: 2250,
  label: 'France',
  type: 'Country'
}, {
  code: 'de',
  id: 2276,
  label: 'Germany',
  type: 'Country'
}, {
  code: 'ie',
  id: 2372,
  label: 'Ireland',
  type: 'Country'
}, {
  code: 'il',
  id: 2376,
  label: 'Israel',
  type: 'Country'
}, {
  code: 'it',
  id: 2380,
  label: 'Italy',
  type: 'Country'
}, {
  code: 'jp',
  id: 2392,
  label: 'Japan',
  type: 'Country'
}, {
  code: 'my',
  id: 2458,
  label: 'Malaysia',
  type: 'Country'
}, {
  code: 'mx',
  id: 2484,
  label: 'Mexico',
  type: 'Country'
}, {
  code: 'nl',
  id: 2528,
  label: 'Netherlands',
  type: 'Country'
}, {
  code: 'nz',
  id: 2554,
  label: 'New Zealand',
  type: 'Country'
}, {
  code: 'no',
  id: 2578,
  label: 'Norway',
  type: 'Country'
}, {
  code: 'pt',
  id: 2620,
  label: 'Portugal',
  type: 'Country'
}, {
  code: 'sa',
  id: 2682,
  label: 'Saudi Arabia',
  type: 'Country'
}, {
  code: 'sg',
  id: 2702,
  label: 'Singapore',
  type: 'Country'
}, {
  code: 'sk',
  id: 2703,
  label: 'Slovakia',
  type: 'Country'
}, {
  code: 'za',
  id: 2710,
  label: 'South Africa',
  type: 'Country'
}, {
  code: 'es',
  id: 2724,
  label: 'Spain',
  type: 'Country'
}, {
  code: 'se',
  id: 2752,
  label: 'Sweden',
  type: 'Country'
}, {
  code: 'ch',
  id: 2756,
  label: 'Switzerland',
  type: 'Country'
}, {
  code: 'tr',
  id: 2792,
  label: 'Turkey',
  type: 'Country'
}, {
  code: 'ae',
  id: 2784,
  label: 'United Arab Emirates',
  type: 'Country'
}, {
  code: 'gb',
  id: 2826,
  label: 'United Kingdom',
  type: 'Country'
}, {
  code: 'us',
  id: 2840,
  label: 'United States',
  type: 'Country'
}, {
  code: 'vn',
  id: 2704,
  label: 'Vietnam',
  type: 'Country'
}];
export default [ANYWHERE].concat(BASIC_LOCATIONS);