export default {
  APP_VERSION_CHECK_INTERVAL: 15 * 60 * 1000,
  // every 15 min
  CONNECTION_RETRY_DELAY: 1000,
  // 1000 ms
  DATA_FETCH_TIMEOUT: 3 * 1000,
  // 3 seconds
  MAX_PPC: 100,
  MAX_RANK: 100,
  MAX_REQUEST_TIMEOUT: 30 * 1000,
  // 30 seconds
  NOT_AVAILABLE: 'N/A',
  NO_VALUE: -1,
  SCROLL_ANIMATION_DURATION: 300,
  // 300 ms
  USER_CHECK_INTERVAL: 60 * 1000,
  // every minute
  USER_FRESH_LIMIT_MINUTES: 60 // new user is when registered in last 60 minutes
};