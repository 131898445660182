const ErrorCodes = {
  ACCESS_DENIED: 403,
  CONFLICT: 409,
  FAILED_DEPENDENCY: 424,
  FETCH_ERROR: -2,
  INTERNAL_SERVER_ERROR: 500,
  LOCKED: 423,
  NOT_FOUND: 404,
  PARSE_ERROR: -3,
  REQUEST_TIMEOUT: 408,
  SERVICE_UNAVAILABLE: 503,
  TOO_MANY_REQUESTS: 429,
  UNATHORIZED: 401,
  UNAUTHORIZED: 401,
  UNCAUGHT_ERROR: -1,
  UNPROCESSABLE_ENTITY: 422
};
export const INTERNAL_TIMEOUT_ERROR_PAYLOAD = {
  status: ErrorCodes.REQUEST_TIMEOUT,
  text: 'Internal timeout.'
};
export const INTERNAL_UNCAUGHT_ERROR_PAYLOAD = {
  status: ErrorCodes.UNCAUGHT_ERROR,
  text: 'Internal uncaught error.'
};
export const TOO_MANY_REQUESTS_TEXT = 'Too many requests';
export const NOT_ENOUGH_LIMITS_TEXT = 'Not enough limits';
export default ErrorCodes;