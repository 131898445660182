module.exports.dataForSeoLocations = [
  {
    code: 'us',
    id: 0,
    label: 'Anywhere',
    type: 'Default',
  },
  {
    code: 'dz',
    id: 2012,
    label: 'Algeria',
    type: 'Country',
  },
  {
    code: 'ao',
    id: 2024,
    label: 'Angola',
    type: 'Country',
  },
  {
    code: 'az',
    id: 2031,
    label: 'Azerbaijan',
    type: 'Country',
  },
  {
    code: 'ar',
    id: 2032,
    label: 'Argentina',
    type: 'Country',
  },
  {
    code: 'au',
    id: 2036,
    label: 'Australia',
    type: 'Country',
  },
  {
    code: 'at',
    id: 2040,
    label: 'Austria',
    type: 'Country',
  },
  {
    code: 'bh',
    id: 2048,
    label: 'Bahrain',
    type: 'Country',
  },
  {
    code: 'bd',
    id: 2050,
    label: 'Bangladesh',
    type: 'Country',
  },
  {
    code: 'am',
    id: 2051,
    label: 'Armenia',
    type: 'Country',
  },
  {
    code: 'be',
    id: 2056,
    label: 'Belgium',
    type: 'Country',
  },
  {
    code: 'bo',
    id: 2068,
    label: 'Bolivia',
    type: 'Country',
  },
  {
    code: 'br',
    id: 2076,
    label: 'Brazil',
    type: 'Country',
  },
  {
    code: 'bg',
    id: 2100,
    label: 'Bulgaria',
    type: 'Country',
  },
  {
    code: 'mm',
    id: 2104,
    label: 'Myanmar (Burma)',
    type: 'Country',
  },
  {
    code: 'kh',
    id: 2116,
    label: 'Cambodia',
    type: 'Country',
  },
  {
    code: 'cm',
    id: 2120,
    label: 'Cameroon',
    type: 'Country',
  },
  {
    code: 'ca',
    id: 2124,
    label: 'Canada',
    type: 'Country',
  },
  {
    code: 'lk',
    id: 2144,
    label: 'Sri Lanka',
    type: 'Country',
  },
  {
    code: 'cl',
    id: 2152,
    label: 'Chile',
    type: 'Country',
  },
  {
    code: 'tw',
    id: 2158,
    label: 'Taiwan',
    type: 'Region',
  },
  {
    code: 'co',
    id: 2170,
    label: 'Colombia',
    type: 'Country',
  },
  {
    code: 'cr',
    id: 2188,
    label: 'Costa Rica',
    type: 'Country',
  },
  {
    code: 'hr',
    id: 2191,
    label: 'Croatia',
    type: 'Country',
  },
  {
    code: 'cy',
    id: 2196,
    label: 'Cyprus',
    type: 'Country',
  },
  {
    code: 'cz',
    id: 2203,
    label: 'Czechia',
    type: 'Country',
  },
  {
    code: 'dk',
    id: 2208,
    label: 'Denmark',
    type: 'Country',
  },
  {
    code: 'ec',
    id: 2218,
    label: 'Ecuador',
    type: 'Country',
  },
  {
    code: 'sv',
    id: 2222,
    label: 'El Salvador',
    type: 'Country',
  },
  {
    code: 'ee',
    id: 2233,
    label: 'Estonia',
    type: 'Country',
  },
  {
    code: 'fi',
    id: 2246,
    label: 'Finland',
    type: 'Country',
  },
  {
    code: 'fr',
    id: 2250,
    label: 'France',
    type: 'Country',
  },
  {
    code: 'de',
    id: 2276,
    label: 'Germany',
    type: 'Country',
  },
  {
    code: 'gh',
    id: 2288,
    label: 'Ghana',
    type: 'Country',
  },
  {
    code: 'gr',
    id: 2300,
    label: 'Greece',
    type: 'Country',
  },
  {
    code: 'gt',
    id: 2320,
    label: 'Guatemala',
    type: 'Country',
  },
  {
    code: 'hk',
    id: 2344,
    label: 'Hong Kong',
    type: 'Region',
  },
  {
    code: 'hu',
    id: 2348,
    label: 'Hungary',
    type: 'Country',
  },
  {
    code: 'in',
    id: 2356,
    label: 'India',
    type: 'Country',
  },
  {
    code: 'id',
    id: 2360,
    label: 'Indonesia',
    type: 'Country',
  },
  {
    code: 'ie',
    id: 2372,
    label: 'Ireland',
    type: 'Country',
  },
  {
    code: 'il',
    id: 2376,
    label: 'Israel',
    type: 'Country',
  },
  {
    code: 'it',
    id: 2380,
    label: 'Italy',
    type: 'Country',
  },
  {
    code: 'ci',
    id: 2384,
    label: "Cote d'Ivoire",
    type: 'Country',
  },
  {
    code: 'jp',
    id: 2392,
    label: 'Japan',
    type: 'Country',
  },
  {
    code: 'kz',
    id: 2398,
    label: 'Kazakhstan',
    type: 'Country',
  },
  {
    code: 'jo',
    id: 2400,
    label: 'Jordan',
    type: 'Country',
  },
  {
    code: 'ke',
    id: 2404,
    label: 'Kenya',
    type: 'Country',
  },
  {
    code: 'kr',
    id: 2410,
    label: 'South Korea',
    type: 'Country',
  },
  {
    code: 'lv',
    id: 2428,
    label: 'Latvia',
    type: 'Country',
  },
  {
    code: 'lt',
    id: 2440,
    label: 'Lithuania',
    type: 'Country',
  },
  {
    code: 'my',
    id: 2458,
    label: 'Malaysia',
    type: 'Country',
  },
  {
    code: 'mt',
    id: 2470,
    label: 'Malta',
    type: 'Country',
  },
  {
    code: 'mx',
    id: 2484,
    label: 'Mexico',
    type: 'Country',
  },
  {
    code: 'ma',
    id: 2504,
    label: 'Morocco',
    type: 'Country',
  },
  {
    code: 'nl',
    id: 2528,
    label: 'Netherlands',
    type: 'Country',
  },
  {
    code: 'nz',
    id: 2554,
    label: 'New Zealand',
    type: 'Country',
  },
  {
    code: 'ni',
    id: 2558,
    label: 'Nicaragua',
    type: 'Country',
  },
  {
    code: 'ng',
    id: 2566,
    label: 'Nigeria',
    type: 'Country',
  },
  {
    code: 'no',
    id: 2578,
    label: 'Norway',
    type: 'Country',
  },
  {
    code: 'pk',
    id: 2586,
    label: 'Pakistan',
    type: 'Country',
  },
  {
    code: 'pa',
    id: 2591,
    label: 'Panama',
    type: 'Country',
  },
  {
    code: 'py',
    id: 2600,
    label: 'Paraguay',
    type: 'Country',
  },
  {
    code: 'pe',
    id: 2604,
    label: 'Peru',
    type: 'Country',
  },
  {
    code: 'ph',
    id: 2608,
    label: 'Philippines',
    type: 'Country',
  },
  {
    code: 'pl',
    id: 2616,
    label: 'Poland',
    type: 'Country',
  },
  {
    code: 'pt',
    id: 2620,
    label: 'Portugal',
    type: 'Country',
  },
  {
    code: 'ro',
    id: 2642,
    label: 'Romania',
    type: 'Country',
  },
  {
    code: 'sa',
    id: 2682,
    label: 'Saudi Arabia',
    type: 'Country',
  },
  {
    code: 'sn',
    id: 2686,
    label: 'Senegal',
    type: 'Country',
  },
  {
    code: 'rs',
    id: 2688,
    label: 'Serbia',
    type: 'Country',
  },
  {
    code: 'sg',
    id: 2702,
    label: 'Singapore',
    type: 'Country',
  },
  {
    code: 'sk',
    id: 2703,
    label: 'Slovakia',
    type: 'Country',
  },
  {
    code: 'vn',
    id: 2704,
    label: 'Vietnam',
    type: 'Country',
  },
  {
    code: 'si',
    id: 2705,
    label: 'Slovenia',
    type: 'Country',
  },
  {
    code: 'za',
    id: 2710,
    label: 'South Africa',
    type: 'Country',
  },
  {
    code: 'es',
    id: 2724,
    label: 'Spain',
    type: 'Country',
  },
  {
    code: 'se',
    id: 2752,
    label: 'Sweden',
    type: 'Country',
  },
  {
    code: 'ch',
    id: 2756,
    label: 'Switzerland',
    type: 'Country',
  },
  {
    code: 'th',
    id: 2764,
    label: 'Thailand',
    type: 'Country',
  },
  {
    code: 'ae',
    id: 2784,
    label: 'United Arab Emirates',
    type: 'Country',
  },
  {
    code: 'tn',
    id: 2788,
    label: 'Tunisia',
    type: 'Country',
  },
  {
    code: 'tr',
    id: 2792,
    label: 'Turkey',
    type: 'Country',
  },
  {
    code: 'ua',
    id: 2804,
    label: 'Ukraine',
    type: 'Country',
  },
  {
    code: 'mk',
    id: 2807,
    label: 'North Macedonia',
    type: 'Country',
  },
  {
    code: 'eg',
    id: 2818,
    label: 'Egypt',
    type: 'Country',
  },
  {
    code: 'gb',
    id: 2826,
    label: 'United Kingdom',
    type: 'Country',
  },
  {
    code: 'us',
    id: 2840,
    label: 'United States',
    type: 'Country',
  },
  {
    code: 'bf',
    id: 2854,
    label: 'Burkina Faso',
    type: 'Country',
  },
  {
    code: 'uy',
    id: 2858,
    label: 'Uruguay',
    type: 'Country',
  },
  {
    code: 've',
    id: 2862,
    label: 'Venezuela',
    type: 'Country',
  },
];
