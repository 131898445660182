const { analytics} = require('./analytics.js');
const md5 = require('md5');
const unleash = require('mangools-commons/lib/scripts/Unleash');
const ResetCountFormatterService = require('mangools-commons/lib/services/ResetCountFormatterService').default;
const NumberFormatterService = require('mangools-commons/lib/services/NumberFormatterService').default;
const UsercomService = require('mangools-commons/lib/services/UsercomService').default;

const { MANGOOLS_APP_HOST, APPS_ROOT_HOST } = process.env;

/**
 * SSO Authentication via mangools.com
 */

module.exports.auth = {
  getUrlParameter(sParam) {
    const sPageURL = decodeURIComponent(window.location.search.substring(1));
    const sURLVariables = sPageURL.split('&');
    let sParameterName = null;
    let i = null;

    for (i = 0; i < sURLVariables.length; i += 1) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1];
      }
    }

    return null;
  },

  main(name) {
    const LOCAL_STORAGE_SSO_TICKET_KEY = 'sso_ticket';
    const SSO_TICKET_PARAM = 'sso_ticket';
    const ssoTicket = this.getUrlParameter(SSO_TICKET_PARAM);
    let userPlan = null;
    let userEmail = null;
    let userId = null;

    let userCreatedAt = null;

    let user = null;

    if (ssoTicket !== undefined && ssoTicket !== null) {
      localStorage.setItem(LOCAL_STORAGE_SSO_TICKET_KEY, ssoTicket);

      if ($('.mg-searchform-options').length) {
        $('.mg-searchform-options').append(`<input type="hidden" name="sso_ticket" value="${ssoTicket}" />`);
      }
    }

    $.ajax({
      url: `${MANGOOLS_APP_HOST}/users/current_user?r=${Date.now()}&sso_ticket=${ssoTicket}`,
      xhrFields: {
        withCredentials: true,
      },
    })
      .done(data => {
        if (data.user) {
          // Hide login/register links and switch with `go to app`
          $('[data-register-btn], [data-signin-btn] ').addClass('uk-hidden');
          $('[data-app-btn]').removeClass('uk-hidden');
          $('[data-acc-btn]').removeClass('uk-hidden');
          $('#open-acc-dropdown').removeClass('uk-hidden');

          if (ssoTicket !== null && ssoTicket !== undefined) {
            $('[data-app-btn] a').attr(
              'href',
              `${APPS_ROOT_HOST}/${name}?ref=header-kw&sso_ticket=${ssoTicket}`,
            );
          } else {
            $('[data-app-btn] a').attr('href', `${APPS_ROOT_HOST}/${name}?ref=header-kw`);
          }



          function addJavascript (url) {
            return new Promise(function (resolve) {
              var tag = document.createElement('script');
              tag.src = url;
              tag.async = true;
              tag.onload = function () {
                resolve();
              };
              document.body.appendChild(tag);
            });
          };

          function addCss (url) {
            return new Promise(function (resolve) {
              var tag = document.createElement('link');
              tag.rel = 'stylesheet';
              tag.href = url;
              tag.onload = function () {
                resolve();
              };
              document.head.appendChild(tag);
            });
          };

          const USER_DROPDOWN_VERSION_SRC = `https://mangools-micro-frontend.s3.amazonaws.com/user-dropdown/${process.env.USER_DROPDOWN_VERSION}`
          const USER_DROPDOWN_VERSION_SRC_JS = `${USER_DROPDOWN_VERSION_SRC}/main.js`
          const USER_DROPDOWN_VERSION_SRC_CSS = `${USER_DROPDOWN_VERSION_SRC}/main.css`

          addCss(USER_DROPDOWN_VERSION_SRC_CSS).then(()=>{
            console.log("User dropdown css loaded")
          }).catch((error)=> {
            console.error("User dropdown css loading error", error)
          })

          addJavascript(USER_DROPDOWN_VERSION_SRC_JS).then(()=>{
            window.MangoolsUserDropdown.initReactApp().then(() => {
              window.MangoolsUserDropdown.isInitialized.then(() => {
                window.MangoolsContext.setUser(({
                  email: data.user.email,
                  isSubUser: data.user.isSubUser,
                  plan: data.user.plan,
                  token: data.user.api_key,
                }));
                window.MangoolsContext.onLogout = (data) => {
                  this.handleLogout();
                };
              }).catch((error)=> {
                console.error("User dropdown initialization error", error)
              })
            }).catch((error)=> {
              console.error("User dropdown initialization error", error)
            })
          })



          console.log(data.user)
          // Save email to variable
          userEmail = data.user.email;
          userId = data.user.id;
          userPlan = data.user.plan;
          userApiToken = data.user.api_key;
          userCreatedAt = data.user.created_at;

          user = data.user;

          // Fill email into newsletter subscribe input
          $('#subscriber-email').val(userEmail);
          $('.hide-for-loged').addClass('uk-hidden');
          $('.visible-for-loged').removeClass('uk-hidden');

          // Gravatar
          var gravatarHash = md5(userEmail);
          $('#open-acc-dropdown .mg-gravatar')
            .attr('src', 'https://secure.gravatar.com/avatar/' + gravatarHash + '.png?d=404&r=PG')
            .attr('alt', userEmail.charAt(0));
          $('.mg-gravatar-letter').text(userEmail.charAt(0));
          $('.user-email').text(userEmail);
          $('.user-plan').text(userPlan);

          if (data.user.isSubUser) {
            $('#dropdown-upgrade').addClass('uk-hidden');
            $('#dropdown-billing').addClass('uk-hidden');
          }

          //remove entry plan for basic and higher plans
          if (['agency', 'basic', 'premium', 'entry', 'custom'].includes(userPlan)) {
            $('.entry-plan').addClass('uk-hidden');
            $('.basic-plan').addClass('uk-width-large-1-3').removeClass('uk-width-large-1-4');
            $('.premium-plan').addClass('uk-width-large-1-3').removeClass('uk-width-large-1-4');
            $('.agency-plan').addClass('uk-width-large-1-3').removeClass('uk-width-large-1-4');
          }
        }
      })
      .fail(function () {
        console.log('There was an error while loading the user data.');
      })
      .always(() => {
        // Initialize chat widget
        setTimeout(() => {
          UsercomService.initChatWidet({
            userId,
            apiKey: process.env.USERCOM_CHAT_WIDGET_API_KEY,
            subdomain: process.env.USER_COM_SUBDOMAIN,
          });
          unleash.gtmLogUnleash();
        }, 2000);
        // Initialize Google analytics
        analytics.init(user);
        unleash.init({ env: process.env.NODE_ENV, userId });
        window.unleash_client = unleash.unleashClient;
      });
  },

  handleLogout() {
    $.ajax({
      url: `${MANGOOLS_APP_HOST}/users/sign_out`,
      xhrFields: {
        withCredentials: true,
      },
    })
      .done(function (data) {
        $('[data-register-btn], [data-signin-btn] ').removeClass('uk-hidden');
        $('[data-app-btn]').addClass('uk-hidden');
        $('[data-acc-btn]').addClass('uk-hidden');
        $('#user-dropdown').addClass('uk-hidden');
        $('.hide-for-loged').removeClass('uk-hidden');
        $('.visible-for-loged').addClass('uk-hidden');
        $('body').append(
          '<div id="logged-out-msg" class="uk-animation-slide-right">You have been logged out.</div>',
        );
        setTimeout(function () {
          $('#logged-out-msg').removeClass('uk-animation-slide-right');
        }, 500);
        setTimeout(function () {
          $('#logged-out-msg').addClass('uk-animation-reverse uk-animation-slide-right');
        }, 3500);
      })
      .fail(function () {
        console.log('There was an error while logging out the user.');
      });
  },

  init(name) {
    this.main(name);
  },
};
