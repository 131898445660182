export const ANY_LANGUAGE = {
  id: 0,
  code: 'en',
  label: 'Any Language'
};
export default [ANY_LANGUAGE, {
  id: 1019,
  code: 'ar',
  label: 'Arabic'
}, {
  id: 1020,
  code: 'bg',
  label: 'Bulgarian'
}, {
  id: 1038,
  code: 'ca',
  label: 'Catalan'
}, {
  id: 1017,
  code: 'zh',
  label: 'Chinese (simplified)'
}, {
  id: 1018,
  code: 'zh',
  label: 'Chinese (traditional)'
}, {
  id: 1039,
  code: 'hr',
  label: 'Croatian'
}, {
  id: 1021,
  code: 'cs',
  label: 'Czech'
}, {
  id: 1009,
  code: 'da',
  label: 'Danish'
}, {
  id: 1010,
  code: 'nl',
  label: 'Dutch'
}, {
  id: 1000,
  code: 'en',
  label: 'English'
}, {
  id: 1043,
  code: 'et',
  label: 'Estonian'
}, {
  id: 1042,
  code: 'fil',
  label: 'Filipino'
}, {
  id: 1011,
  code: 'fi',
  label: 'Finnish'
}, {
  id: 1002,
  code: 'fr',
  label: 'French'
}, {
  id: 1001,
  code: 'de',
  label: 'German'
}, {
  id: 1022,
  code: 'el',
  label: 'Greek'
}, {
  id: 1027,
  code: 'he',
  label: 'Hebrew'
}, {
  id: 1023,
  code: 'hi',
  label: 'Hindi'
}, {
  id: 1024,
  code: 'hu',
  label: 'Hungarian'
}, {
  id: 1026,
  code: 'is',
  label: 'Icelandic'
}, {
  id: 1025,
  code: 'id',
  label: 'Indonesian'
}, {
  id: 1004,
  code: 'it',
  label: 'Italian'
}, {
  id: 1005,
  code: 'ja',
  label: 'Japanese'
}, {
  id: 1012,
  code: 'ko',
  label: 'Korean'
}, {
  id: 1028,
  code: 'lv',
  label: 'Latvian'
}, {
  id: 1029,
  code: 'lt',
  label: 'Lithuanian'
}, {
  id: 1102,
  code: 'ms',
  label: 'Malay'
}, {
  id: 1013,
  code: 'nn',
  label: 'Norwegian'
}, {
  id: 1064,
  code: 'fa',
  label: 'Persian'
}, {
  id: 1030,
  code: 'pl',
  label: 'Polish'
}, {
  id: 1014,
  code: 'pt',
  label: 'Portuguese'
}, {
  id: 1032,
  code: 'ro',
  label: 'Romanian'
}, {
  id: 1031,
  code: 'ru',
  label: 'Russian'
}, {
  id: 1035,
  code: 'sr',
  label: 'Serbian'
}, {
  id: 1033,
  code: 'sk',
  label: 'Slovak'
}, {
  id: 1034,
  code: 'sl',
  label: 'Slovenian'
}, {
  id: 1003,
  code: 'es',
  label: 'Spanish'
}, {
  id: 1015,
  code: 'sv',
  label: 'Swedish'
}, {
  id: 1044,
  code: 'th',
  label: 'Thai'
}, {
  id: 1037,
  code: 'tr',
  label: 'Turkish'
}, {
  id: 1036,
  code: 'uk',
  label: 'Ukrainian'
}, {
  id: 1041,
  code: 'ur',
  label: 'Urdu'
}, {
  id: 1040,
  code: 'vi',
  label: 'Vietnamese'
}];