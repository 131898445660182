import isNil from 'ramda/src/isNil';
class UsercomService {
  static initChatWidet({
    userId,
    apiKey,
    subdomain,
    onMessage,
    onOpen,
    onPayloadReceived
  }) {
    window.civchat = {
      apiKey,
      user_id: userId,
      onMessage,
      onOpen,
      onPayloadReceived
    };
    const ueScript = document.createElement('script');
    ueScript.type = 'text/javascript';
    ueScript.src = `https://${subdomain}.user.com/widget.js`;
    ueScript.setAttribute('data-cfasync', false);
    document.body.appendChild(ueScript);
  }
  static createEvent({
    eventName,
    attributes = {}
  }) {
    if (!isNil(window.userengage)) {
      window.userengage(`event.${eventName}`, attributes);
    }
  }
  static openWidget() {
    if (!isNil(window.userengage)) {
      window.userengage(`widget.open`);
    }
  }
}
export default UsercomService;