import { isNil } from 'ramda';
import ErrorCodes from '../constants/ErrorCodes';
class SourceService {
  static createRequest({
    method,
    parse
  }) {
    return ({
      requestURL,
      headers,
      body = null,
      credentials
    }) => {
      return fetch(requestURL, {
        method,
        mode: 'cors',
        headers,
        credentials,
        ...(!isNil(body) && {
          body: JSON.stringify(body)
        })
      }).then(response => {
        if (response.ok) {
          return response.json().then(data => ({
            error: false,
            payload: parse(data)
          })).catch(ex => ({
            error: true,
            payload: {
              requestURL,
              status: ErrorCodes.PARSE_ERROR,
              text: ex.toString()
            }
          }));
        } else {
          return response.json().then(e => ({
            error: true,
            payload: {
              requestURL,
              status: response.status,
              text: e.error ? e.error.message : null,
              type: e.error ? e.error.type : null
            }
          }));
        }
      }).catch(ex => ({
        error: true,
        payload: {
          requestURL,
          status: ErrorCodes.FETCH_ERROR,
          text: ex.toString()
        }
      }));
    };
  }
}
export { SourceService };