const replaceUselessCharacters = new RegExp(/[^\p{L}\p{N}\p{Sc}\p{M}\s+&#]/, 'iug');
const replaceUselessCharacters2 = new RegExp(/[\t\n]/, 'iug'); // becasue /s contains it
const replacePlus = new RegExp(/([\p{L}\p{N}\s])(\++)([\p{L}\p{N}\s])/, 'iug');
const replacePlus2 = new RegExp(/(^[\s+]+)/, 'iug');
const regexCountWords = new RegExp(/[\p{L}\p{N}\p{Sc}\p{M}&#]{1,}/, 'iu');

function normalizeKeyword(kw) {
	kw = kw.replace(replaceUselessCharacters, ' ');
	kw = kw.replace(replaceUselessCharacters2, ' ');
	kw = kw.replace(replacePlus, '$1 $3');
	kw = kw.replace(replacePlus2, '');
	kw = kw.toLowerCase();
	kw = kw.replace(/["]+/g, '');
	kw = kw.replace(/\s{2,}/g, ' ');
	kw = kw.trim();

	return kw;
};

function normalizeKeywordAndCheckEmpty(kw) {
	const tmp = normalizeKeyword(kw);
	if (!kw) { //e.g. "" https://rollbar.com/itrinitys.r.o./api.mangools.com/items/4466/occurrences/223371286057/
		return null;
	}
	return tmp;
};

function isTooLongKeyword(kw) {
	const countOfWords = kw.split(regexCountWords).length - 1; // -1 cuz first item at array is empty

	return (countOfWords > 10);
};

function isKeywordValid(kw) {
	kw = normalizeKeywordAndCheckEmpty(kw);
	if (!kw) {
		return false;
	}
	if (kw.length > 80 || kw.length < 2) {
		return false; // https://developers.google.com/adwords/api/docs/appendix/limits
	}
	if (isTooLongKeyword(kw)) {
		return false;
	}
	return true;
};

module.exports = {
    init() {
		$("#query-input").change(function(e) {
			const validationAlert = $('#query-input-val-msg');
			const value = e.currentTarget.value;
		
			if ((value.length === 0 || isKeywordValid(value)) && !validationAlert.hasClass('uk-hidden')) {
				validationAlert.addClass('uk-hidden')
			}
		});
		
		$('#search-form').submit(function(e) {			
			if (!isKeywordValid($('#query-input').val())) {
				e.preventDefault();

				$('#query-input-val-msg').removeClass('uk-hidden');
		
				return false;
			}
		
			return true;
		});
	}
}
