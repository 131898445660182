import numeral from 'numeral';
const NON_BREAKING_SPACE = String.fromCharCode(160);

/**
 * Number Utility functions.
 * Wrapper around numeral.
 */
class NumberFormatterService {
  /**
   * Format number without decimals, separate with commas.
   *
   * @param {value: number} Input number.
   * @return {string} Formatted number.
   */
  static simpleCommaNoDecimals(value) {
    return numeral(value).format('0,0');
  }

  /**
   * Format number with two decimals.
   *
   * @param {value: number} Input number.
   * @return {string} Formatted number.
   */
  static simpleTwoDecimals(value) {
    return numeral(value).format('0.00');
  }

  /**
   * Format number with one decimal.
   *
   * @param {value: number} Input number.
   * @return {string} Formatted number.
   */
  static simpleOneDecimal(value) {
    return numeral(value).format('0.0');
  }

  /**
   * Fancy format number without decimals.
   *
   * @param {value: number} Input number.
   * @return {string} Formatted number.
   */
  static informalNoDecimal(value) {
    const formatted = numeral(value).format('0a');
    return formatted.replace(/\s/g, NON_BREAKING_SPACE);
  }

  /**
   * Format number with preserving the length.
   *
   * @param {value: number} Input number.
   * @returns {string} Formatted number.
   */
  static fullNoDecimal(value) {
    return numeral(value).format('0,0');
  }

  /**
   * Fancy format number with decimals.
   *
   * @param {value: number} Input number.
   * @return {string} Formatted number.
   */
  static informalWithDecimal(value) {
    const formatted = numeral(value).format('0.[0]a');
    return formatted.replace(/\s/g, NON_BREAKING_SPACE);
  }

  /**
   * Money format number.
   *
   * @param {value: number} Input number.
   * @return {string} Formatted number as money.
   */
  static moneyFormat(value) {
    return numeral(value).format('$0,0.00');
  }
}
export default NumberFormatterService;